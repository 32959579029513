<template>
  <div class="loading-box">
    <svg class="loading-box-icon focus-in-expand">
      <use xlink:href="#peach-icon" style="height: 100%;width: 100%;"></use>
    </svg>
    <h1 class="loading-title focus-in-expand">
      What To Eat
    </h1>
  </div>
</template>

<script>
export default {
  name: "LoadingBox"
}
</script>

<style scoped lang="less">
  .loading-box{
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: var(--first-theme-color);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .loading-box-icon{
      width: 100px;
      height: 100px;
      margin-left: 10px;
      //-webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      //animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
    .loading-title{
      position: relative;
      font-size: 30px;
      font-weight: bold;
      //-webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      //animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
  }
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .focus-in-expand {
    -webkit-animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-11-12 14:20:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation focus-in-expand
   * ----------------------------------------
   */
  @-webkit-keyframes focus-in-expand {
    0% {
      letter-spacing: -0.5em;
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes focus-in-expand {
    0% {
      letter-spacing: -0.5em;
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }

</style>